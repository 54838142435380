import { navigateToUrl } from 'single-spa';

/**
 * @property newTab {boolean} true for opening in a new tab
 * @property reload {boolean} true for full reloading page
 */
export type NavigationOptions = {
  newTab?: boolean | unknown;
  reload?: boolean | unknown;
};

/**
 * Central function for all navigation requests\
 * href event can be passed as first parameter\
 * `<a href="/new-url" onclick="navigateTo(event)">My link</a>`\
 * See [single-spa](https://single-spa.js.org/docs/api/#navigatetourl) docs for more
 *
 * Not using this method would lead to full page reloads and other shenanigans
 * @param path Relative to app root or a link tag onclick event
 * @param options {NavigationOptions}
 */
export async function navigateTo(
  path: string | unknown,
  options: NavigationOptions = {}
): Promise<void> {
  const { newTab, reload } = options;
  console.info('Navigating to', path, {
    newTab,
    reload,
  });

  // Open in new tab
  if (newTab) {
    window.open(path as string, '_blank');
    return;
  }

  // Supported so we can go to webapp - triggers full page reload
  if (reload) {
    window.open(path as string, '_self');
    return;
  }

  // Normal SPA navigation
  navigateToUrl(path as string);
}
